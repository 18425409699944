import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import withPrismicPreview from '$utils/withPrismicPreview';
import { SEO } from '$components/global/SEO';
import { PageContainer as _PageContainer } from '$components/global/PageContainer';
import { usePageContext } from '$store';

import { rem, media, mixins, typo } from '$styles';
import { PrismicSpan, PrismicText } from '$components/text';
import { Icon } from '$components/image';
import { Link } from '$components/global/Link';
import { CTA } from '$components/form/CTA';
import { Background404 } from '$components/image/Background404';

export const pageQuery = graphql`
  query ($uid: String!, $lang: String!) {
		prismicPage404(uid: { eq: $uid }, lang: { eq: $lang }) {
			...Base_404
			data {
				...SEO_404
				title { text }
				baseline
				ctaLink: back_link { target url type id }
				ctaText: back_link_text
			}
		}
	}
`;

export default withPrismicPreview(({ className, ...props }) => {
	const data = props.data?.prismicPage404?.data || {};
	const { siteData } = usePageContext();
	const homepage = siteData?.header_homepage?.url ?? '/';

	return (
		<PageContainer className={className} data={data}>
			<SEO {...data} />
			<div className="wrapper">
				<figure className="logo">
					<Link to={homepage}>
						<Icon id="logo" />
					</Link>
				</figure>
				<div className="content">
					<h1>
						<Icon id="dash" />
						<PrismicSpan text={data.title} />
						<Icon id="dash" />
					</h1>
					<PrismicText tag="h3" text={data.baseline} />
					<CTA background="small" {...{ ctaLink: data.back_link, ctaText: data.back_link_text }} />
				</div>
			</div>
			<Background404 />
		</PageContainer>
	);
});

const PageContainer = styled(_PageContainer)`
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0;


	--height-logo: ${ rem(70) };
	${ media.mobile` --height-logo: ${ rem(45) }; ` }

	.wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		margin: ${ rem(20) };
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		justify-content: space-between;
		text-align: center;
	}

	.logo {
		position: absolute;
		z-index: 10;
		flex: 0 0 auto;
		a {
			display: block;
		}
	}

	.content {
		flex: 1 1 auto;
		${ mixins.flexCenter }
		margin-top: ${ rem(-20) };
		flex-flow: column nowrap;
	}

	.icon-logo {
		height: var(--height-logo);
		width: auto;

	}

	h1 {
		${ typo.h1 }
		font-size: ${ rem(110) };
		${ media.mobile` font-size: ${ rem(110) }; ` }
	}

	h3 {
		${ typo.h3 }
		margin-top: 2em;
	}

	.cta {
		margin-top: 3em;
	}
`;

