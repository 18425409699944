import propsToDom from '$utils/propsToDom';
import React, { useCallback, useRef } from 'react';
import { useIsomorphicLayoutEffect, useMeasure } from 'react-use';
import styled from 'styled-components';

const URLS = [ '/images/404/croc.png', '/images/404/brush.png' ];

const FADE_DELAY = 500;

const Background404Component = ({ ...others }) => {
	const [ $container, { width, height } ] = useMeasure();
	const $canvas = useRef(null);
	const vars = useRef({ ctx: null, crocodile: null, brush: null, rafId: null });
	const mouse = useRef([]);

	// ------------------------------------------------------------
	//    UPDATE
	// ------------------------------------------------------------
	const update = useCallback(() => {
		const { ctx, crocodile, brush } = vars.current;
		const now = Date.now();

		if (ctx && crocodile && brush && $canvas.current && width > 0) {
			const cr = crocodile.width / crocodile.height;
			const tw = Math.round(width * (1030 / 1366));
			const th = Math.round(tw / cr);
			const crocScale = Math.max(0.35, tw / crocodile.width);

			// Clear canvas
			// eslint-disable-next-line
      $canvas.current.width = width;

			// Draw all brushes at scale
			mouse.current.forEach(([ x, y, timestamp ]) => {
				const progress = Math.min(now - timestamp, FADE_DELAY) / FADE_DELAY;
				const scale = (1 - 0.5 * progress) * crocScale;
				const w = brush.width * scale;
				const h = brush.height * scale;
				ctx.globalAlpha = 1 - progress;
				ctx.drawImage(brush, x - w / 2, y - h / 2, w, h);
			});

			ctx.globalAlpha = 1;
			ctx.globalCompositeOperation = 'destination-out';
			ctx.drawImage(crocodile, width / 2 - tw / 2, height / 2 - th / 2, tw, th);

			// Remove all hidden brushes
			mouse.current = mouse.current.filter(([ , , timestamp ]) => {
				const scale = 1 - Math.min(now - timestamp, FADE_DELAY) / FADE_DELAY;
				return scale > 0;
			});
		}

		vars.current.rafId = window.requestAnimationFrame(update);
	}, [ width, height ]);

	// ------------------------------------------------------------
	//    FUNCTIONS
	// ------------------------------------------------------------
	const handleLoad = (event) => {
		if (event.target.src.indexOf('croc') > -1) {
			vars.current.crocodile = event.target;
		} else {
			vars.current.brush = event.target;
		}
	};

	// ------------------------------------------------------------
	//    EFFECTS
	// ------------------------------------------------------------
	const handleMouseMove = (event) => {
		const data = event.changedTouches ? event.changedTouches[ 0 ] : event;
		mouse.current.unshift([ data.pageX, data.pageY, Date.now() ]);
	};

	useIsomorphicLayoutEffect(() => {
		vars.current.rafId = window.requestAnimationFrame(update);

		return () => {
			window.cancelAnimationFrame(vars.current.rafId);
		};
	}, [ update ]);

	useIsomorphicLayoutEffect(() => {
		URLS.forEach((url) => {
			const image = new Image();
			image.onload = handleLoad;
			image.src = url;
		});

		vars.current.ctx = $canvas.current.getContext('2d');
		window.addEventListener('mousemove', handleMouseMove, { passive: true });
		window.addEventListener('touchmove', handleMouseMove, { passive: true });

		return () => {
			window.removeEventListener('mousemove', handleMouseMove, { passive: true });
			window.removeEventListener('touchmove', handleMouseMove, { passive: true });
		};
	}, []);

	return (
		<div ref={$container} {...propsToDom(others)}>
			<canvas ref={$canvas} width={width} height={height}></canvas>
		</div>
	);
};

export const Background404 = styled(Background404Component)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  canvas {
    width: 100%;
    height: 100%;
  }
`;
